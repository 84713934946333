import React, {useEffect, useState} from 'react'
import {CircularProgress, makeStyles, Slider, withStyles} from "@material-ui/core"

import {API_OFFSETS_GET} from "../../constants/urls"

import "./Calculator.scss"

const useStyles = makeStyles({
    green: {
        color: "#04A996",
    },
});

function Progress({step, max}: {step: number, max: number}) {
    const classes = useStyles();

    if (step > max) {
        return <div className={"progress-container"}/>
    }

    return (
        <div className={"progress-container"}>
            <CircularProgress className={classes.green} variant="determinate" size={16} value={step*100/max} />
            <div className={"progress-text"}>{step}/{max}</div>
        </div>
    )
}

function Next({enabled, action, back}: {enabled: boolean, action: () => void, back: (() => void) | null}) {
    return (
        <div className="Calculator-block-footer" style={{position: "relative"}}>
            {back && (
                <div className="next-back-button" onClick={back} style={{position: "absolute", left: "40px"}}>← Back</div>
            )}
            <button onClick={action} className={"next-button" + (enabled ? " next-button-enabled" : "")}>Next</button>
        </div>
    )
}

function ButtonCircle({data, value, selector, onClick, children}: any) {
    let className = 'Calculator-select-button';
    if (selector(data, value)) {
        className += ' Calculator-select-button-selected'
    }

    function setValue() {
        onClick(value)
    }

    return (
        <div className={className} onClick={setValue}>{children}</div>
    )
}

function equality(data: string, value: string) {
    return data === value
}

function inTheList(data: string[], value: string) {
    return data.includes(value)
}

const STEP_COUNTRY = 1
const STEP_HOUSING = 2
const STEP_TRANSPORT = 3
const STEP_FLIGHTS = 4
const STEP_CALCULATE = 5

const FLIGHT_MARKS = [
    { value: 0, label: '0' },
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 },
    { value: 7 },
    { value: 8 , label: '8+'},
];

const FlightSlider = withStyles({
    root: {
        color: '#04A996',
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -12,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    rail: {
        color: "#E1E4E8",
    },
})(Slider);

function Offset({name, thumbnailUrl}: {name: string, thumbnailUrl: string}) {
    return (
        <div className="strategy-box">
            <img className="strategy-image" src={thumbnailUrl} alt="solar"/>
            <p className="strategy-text">{name}</p>
        </div>
    )
}

export function Calculator() {
    const [step, setStep] = useState(STEP_COUNTRY)
    const [loading, setLoading] = useState(false)
    const [country, setCountry] = useState<string|null>(null)
    const [housing, setHousing] = useState<string|null>(null)
    const [transport, setTransport] = useState<Array<string>>([])
    const [shortFlights, setShortFlights] = useState(0)
    const [longFlights, setLongFlights] = useState(0)
    const [result, setResult] = useState(null)
    const [error, setError] = useState<string|null>(null)
    const [offsets, setOffsets] = useState<{name: string, thumbnailUrl: string}[] | null>(null)

    useEffect(() => {
        (async () => {
            const response = await fetch(API_OFFSETS_GET)
            if (response.status === 200) {
                const data = await response.json()
                setOffsets(data.slice(0, 4));
            }
        })()
    }, [])

    const handleShortFlightChange = (event: any, newValue: number) => {
        setShortFlights(newValue);
    };

    const handleLongFlightChange = (event: any, newValue: number) => {
        setLongFlights(newValue);
    };

    function toggleItem(item: string) {
        if (transport.includes(item)) {
            setTransport(transport.filter(i => i !== item))
        }
        else {
            setTransport([...transport, item])
        }
    }

    let title;
    let nextEnabled = false;
    switch (step) {
        case STEP_COUNTRY:
            title = "My country"
            nextEnabled = !!country
            break
        case STEP_HOUSING:
            title = "My housing type"
            nextEnabled = !!housing
            break
        case STEP_TRANSPORT:
            title = "My main transport modes"
            nextEnabled = transport.length > 0
            break
        case STEP_FLIGHTS:
            title = "My number of flights per year"
            nextEnabled = true
            break
        default:
            title = "My carbon footprint score"
            break
    }

    async function nextAction() {
        if (!nextEnabled) {
            return
        }

        if (step === 4) {
            setLoading(true)
            try {
                const response = await fetch(API_OFFSETS_GET, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        country,
                        transport: {
                            modes: transport,
                            flights: {
                                short_numberPerYear: shortFlights,
                                long_numberPerYear: longFlights,
                            }
                        }
                    })
                })
                const data = await response.json()
                console.log(response, data)
                if (response.status !== 200) {
                    setError(data.message || "Raw calculator request failed")
                }
                else {
                    setResult(data.total_tCO2PerYear.toFixed(2))
                }
            }
            catch (e) {
                setError("Network error: " + e.message)
            }
            setLoading(false)
        }

        setStep(step + 1)
    }

    function back() {
        setStep(step - 1)
    }

    if (step === STEP_CALCULATE) {
        return (
            <div className="Calculator">
                <div className="Calculator-block">
                    <div className="Calculator-block-title" style={{marginTop: "60px", marginBottom: "9px"}}>My carbon footprint score</div>
                    <div className="Calculator-block-score">{error ? "error" : result}<sup>*</sup></div>

                    <div className="Calculator-block-score-footer">Ton CO<sub>2</sub> per year</div>
                    <div className="strategy-header">GetGreen by these solutions:</div>
                    <div className="strategy-container">
                        {offsets && offsets.map(o => <Offset name={o.name} thumbnailUrl={o.thumbnailUrl}/>)}
                    </div>
                    <div style={{flexGrow: 1}}/>
                    <div className="Calculator-block-footer" style={{height: "155px"}}>
                        <div>* {error || "Estimate based on approximate calculation."}</div>
                    </div>
                </div>
                <div className="Calculator-footer">
                    &copy; 2021 Copyright / All Rights Reserved - Scone BV
                </div>
            </div>
        )
    }


    return (
        <div className="Calculator">
            <div className="Calculator-title">Calculate my carbon footprint</div>
            <div className="Calculator-block">
                <Progress step={step} max={4}/>
                <div className="Calculator-block-title">{title}</div>
                {step === STEP_COUNTRY && (
                    <>
                        <div className="Calculator-block-content">
                            <ButtonCircle data={country} value={'be'} selector={equality} onClick={setCountry}>
                                <img className="icon-image" alt="Belgium" src="images/calculator/Belgium.png"/>
                            </ButtonCircle>
                            <ButtonCircle data={country} value={'nl'} selector={equality} onClick={setCountry}>
                                <img className="icon-image" alt="Netherlands" src="images/calculator/Netherlands.png"/>
                            </ButtonCircle>
                        </div>
                    </>
                )}
                {step === STEP_HOUSING && (
                    <>
                        <div className="Calculator-block-content">
                            <ButtonCircle data={housing} value={'detached'} selector={equality} onClick={setHousing}>
                                <img className="icon-image" alt="Detached house" src="images/calculator/detached_house.png"/>
                            </ButtonCircle>
                            <ButtonCircle data={housing} value={'semidetached'} selector={equality} onClick={setHousing}>
                                <img className="icon-image" alt="Semidetached house" src="images/calculator/semi_detached_house.png"/>
                            </ButtonCircle>
                            <ButtonCircle data={housing} value={'terraced'} selector={equality} onClick={setHousing}>
                                <img className="icon-image" alt="Terraced house" src="images/calculator/terraced_house.png"/>
                            </ButtonCircle>
                            <ButtonCircle data={housing} value={'flat'} selector={equality} onClick={setHousing}>
                                <img className="icon-image" alt="Flat" src="images/calculator/apartment.png"/>
                            </ButtonCircle>
                        </div>
                    </>
                )}
                {step === STEP_TRANSPORT && (
                    <>
                        <div className="Calculator-block-content">
                            <ButtonCircle data={transport} value={'bike'} selector={inTheList} onClick={toggleItem}>
                                <img className="icon-image" alt="Bicycle" src="images/calculator/bicycle.png"/>
                            </ButtonCircle>
                            <ButtonCircle data={transport} value={'motorbike'} selector={inTheList} onClick={toggleItem}>
                                <img className="icon-image" alt="Motorbike" src="images/calculator/motorbike.png"/>
                            </ButtonCircle>
                            <ButtonCircle data={transport} value={'car'} selector={inTheList} onClick={toggleItem}>
                                <img className="icon-image" alt="Car" src="images/calculator/car.png"/>
                            </ButtonCircle>
                            <ButtonCircle data={transport} value={'electric-car'} selector={inTheList} onClick={toggleItem}>
                                <img className="icon-image" alt="Electric car" src="images/calculator/e-car.png"/>
                            </ButtonCircle>
                            <ButtonCircle data={transport} value={'public-transport'} selector={inTheList} onClick={toggleItem}>
                                <img className="icon-image" alt="Public transport" src="images/calculator/public-transport.png"/>
                            </ButtonCircle>
                        </div>
                    </>
                )}
                {step === STEP_FLIGHTS && (
                    <>
                        <div className="Calculator-block-content">
                            <div style={{display: "flex", flexDirection: "row", height: "100px"}}>
                                <div style={{marginRight: "40px"}} className="slider-container">
                                    <div id="short-flights-label" style={{marginBottom: "20px"}}>Short flights: &lt; 4 hours</div>
                                    <FlightSlider
                                        aria-labelledby="short-flights-label"
                                        step={1}
                                        marks={FLIGHT_MARKS}
                                        min={0}
                                        max={8}
                                        valueLabelDisplay="auto"
                                        value={shortFlights}
                                        onChange={handleShortFlightChange as any}
                                    />
                                </div>
                                <div className="slider-container">
                                    <div id="long-flights-label" style={{marginBottom: "20px"}}>Long flights: over 4 hours</div>
                                    <FlightSlider
                                        aria-labelledby="long-flights-label"
                                        step={1}
                                        marks={FLIGHT_MARKS}
                                        min={0}
                                        max={8}
                                        valueLabelDisplay="auto"
                                        value={longFlights}
                                        onChange={handleLongFlightChange as any}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div style={{flexGrow: 1}}/>
                <Next enabled={nextEnabled && !loading} action={nextAction} back={step > 1 ? back : null}/>
            </div>
            <div className="Calculator-footer">
                &copy; 2021 Copyright / All Rights Reserved - Scone BV
            </div>
        </div>
    )
}
