import './ContributionOption.scss'
import { SconeCard } from '../Utils/Card';
import { useHistory } from 'react-router-dom';
import { email, firstName, lastName } from '../Offset/SubscriptionPage';
import {ROUTE_OFFSETS_PAYMENT} from "../../constants/routes";
import {useContentfulEntity} from "../../Hooks/contentful";
import {CONTENTFUL_ENTITY_OFFSET_LANDING} from "../../constants/contentful";
import { useRecoilState } from 'recoil';
import { paymentObjectState } from '../../store';

function ContributionOption(props: ContributionOptionProps) {
    const history = useHistory()
    const offsetsEntity = useContentfulEntity(CONTENTFUL_ENTITY_OFFSET_LANDING)
    const setPaymentObject = useRecoilState(paymentObjectState)[1];

    function goToPaymentPage() {
        setPaymentObject({
            price: Number(props.price.toFixed(0)),
            title: props.title,
            amountOfCO2: props.amountOfCO2,
            description: props.description,
            email: email,
            firstName: firstName,
            lastName: lastName
        })
        history.push(ROUTE_OFFSETS_PAYMENT);
    }

    return <SconeCard className='contributionOptionContainer'>
        <div className='optionTitle'>{props.title}</div>
        <div className='optionSubTitle'>{offsetsEntity?.fields.tileCoverTextTemplate.replace('{AMOUNT}', props.amountOfCO2)}</div>
        <div className='optionPriceContainer'>
            <span className='optionPrice'>&euro;{props.showDecimals ? props.price.toFixed(2) : props.price.toFixed(0) + ',-'}</span>
            <span className={props.showDecimals ? 'pmNoMargin' : 'pm'}>/maand</span>
        </div>
        <div className='optionDescription'>{props.description}</div>
        <div className='optionExtra'>{offsetsEntity?.fields.tileCallToAction}</div>
        {props.showButton !== false &&
            <button onClick={goToPaymentPage}>{offsetsEntity?.fields.subscribeButtonText}</button>
        }
    </SconeCard>
}

interface ContributionOptionProps {
    title: string;
    amountOfCO2: number;
    price: number;
    description: string;
    showButton?: boolean;
    showDecimals?: boolean;
}

export default ContributionOption
