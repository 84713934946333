import { API_OFFSETS_GET, API_OFFSETS_SETUP_INTENT } from "../constants/urls";

export abstract class OffsettingApiService {
    public static getOffsets(): Promise<Response> {
        return fetch(API_OFFSETS_GET)
    }

    public static getSetupIntent(authenticationToken: string, amount: number): Promise<Response> {
        return fetch(API_OFFSETS_SETUP_INTENT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationToken,
            },
            body: JSON.stringify({
                amount: amount
            })
        })
    }
}