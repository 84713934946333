import { useState } from 'react';
import { validateEmail } from '../Utils/ValidateEmail';
import { UserApiService } from '../../Services/UserApiService';
import { CircularProgress } from '@material-ui/core';
import {useContentfulEntity} from "../../Hooks/contentful";
import {CONTENTFUL_ENTITY_OFFSET_LANDING} from "../../constants/contentful";
import { useRecoilState } from 'recoil';
import { authenticationTokenState } from '../../store';
import { useUserProfile } from '../../Hooks/useUserProfile';
import ApiRequestWrapper from '../Shared/ApiRequestWrapper';
import { SconeCheckbox } from '../Utils/Checkbox';

function AccountForm(props: AccountFormProps) {
    const [email, setEmail] = useState<string>(props.email ?? "");
    const [firstName, setFirstName] = useState<string>(props.firstName ?? "");
    const [lastName, setLastName] = useState<string>(props.lastName ?? "");
    const [tips, setTips] = useState<boolean>(false);
    const [shouldLogin, setShouldLogin] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [emailValidation, setEmailValidation] = useState<string>("");
    const [error, setError] = useState<string>('');

    const [isClicked, setIsClicked] = useState<boolean>(false)

    const [authenticationToken, setAuthenticationToken] = useRecoilState(authenticationTokenState);
    const userProfile = useUserProfile();
    const offsetEntity = useContentfulEntity(CONTENTFUL_ENTITY_OFFSET_LANDING);

    const handleAccountSubmit = async (event: any) => {
        event.preventDefault();
        if (isClicked) {
            return;
        }
        setIsClicked(true);
        let response;
        // TODO: Better error handling
        if (shouldLogin) {
            response = await UserApiService.login(email, password);
            switch (response.status) {
                case 200:
                    setError("")
                    const userData = await response.json()
                    setAuthenticationToken(userData.authenticationToken)
                    break;
                case 401:
                    setError("Password is incorrect");
                    break;
                default:
                    setError("Something went wrong");
            }
        } else {
            response = await UserApiService.createAccount(firstName, lastName, email);
            setIsClicked(false)
            switch (response.status) {
                case 201:
                    const userData = await response.json()
                    setAuthenticationToken(userData.authenticationToken)
                    setError("")
                    break;
                case 409:
                    setShouldLogin(true);
                    setError("This account already exists, please login")
                    break;
                case 422:
                    setError("Some fields aren't filled in correctly");
                    break;
                default:
                    setError("Something went wrong");
            }
        }
        setIsClicked(false)
    }

    function validateEmailField() {
        let error = validateEmail(email) ? '' : 'Not a valid email';
        setEmailValidation(error)
    }

    function canSubmit() {
        return firstName && lastName && email && !emailValidation && (!shouldLogin || password);
    }

    return !authenticationToken ? <form className='checkoutForm' onSubmit={handleAccountSubmit}>
        {error && <div className='shouldLogin'>{error}</div>}
        {shouldLogin && <div className='changeAccount' onClick={() => setShouldLogin(false)}>Use different email</div>}
        <div className="input-container show">
            <input className={emailValidation ? 'error' : (email && 'valid')} type='text' name='email' placeholder='example@domain.com' value={email} onChange={(e) => setEmail(e.target.value)} onBlur={validateEmailField} onFocus={() => setEmailValidation("")}/>
            <label>{offsetEntity?.fields.accountSectionInputEmail}</label>
        </div>
        <div className='double-input-container'>
            <div className="input-container show">
                <input className={firstName && 'valid'} type='text' name='firstName' placeholder='John' value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                <label>{offsetEntity?.fields.accountSectionInputName}</label>
            </div>
            <div className="input-container show">
                <input className={lastName && 'valid'} type='text' name='lastName' placeholder='Doe' value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                <label>{offsetEntity?.fields.accountSectionInputName}</label>
            </div>
        </div>
        {shouldLogin &&
            <div className="input-container show">
                <input className={password && 'valid'} type='password' name='password' placeholder='Fill in you password'
                       value={password} onChange={(e) => setPassword(e.target.value)}/>
                <label>Password</label>
            </div>
        }
        {!shouldLogin &&
            <div className='paymentOptionWarning'>
                <SconeCheckbox checked={tips} onChange={() => setTips(!tips)}/>
                <div>Ik ontvang graag af en toe praktische tips en nuttige weetjes over klimaat en hoe CO2 voorkomen.</div>
            </div>
        }
        <button disabled={!canSubmit()}>
            {isClicked ? <CircularProgress />: (shouldLogin ? 'Login' : offsetEntity?.fields.accountSectionButton)}
        </button>
    </form>
        : <ApiRequestWrapper value={userProfile}>
            {userProfile.data &&
                <div className='checkoutForm'>
                    <div className="input-container show">
                        <input disabled type='text' value={userProfile.data.email}/>
                        <label>{offsetEntity?.fields.accountSectionInputEmail}</label>
                    </div>
                    <div className='double-input-container'>
                        <div className="input-container show">
                            <input disabled type='text' value={firstName}/>
                            <label>{offsetEntity?.fields.accountSectionInputName}</label>
                        </div>
                        <div className="input-container show">
                            <input disabled type='text' value={lastName}/>
                            <label>{offsetEntity?.fields.accountSectionInputName}</label>
                        </div>
                    </div>
                    <div className='changeAccount' onClick={() => setAuthenticationToken(undefined)}>Change account?</div>
                </div>
            }
        </ApiRequestWrapper>
}

interface AccountFormProps {
    email?: string;
    firstName?: string;
    lastName?: string;
}

export default AccountForm
