import './ContributionContainer.scss'
import ContributionOption  from '../../Shared/ContributionOption';
import ContributionOptionAdjustable from './ContributionOptionAdjustable';
import { ContributionOptionData, contributionOptions } from '../../../constants/ContributionOptions';
import MediaQuery from 'react-responsive';
import { footprint } from '../SubscriptionPage';
import PaymentLogos from '../../Shared/PaymentLogos';
import {useContentfulEntity} from "../../../Hooks/contentful";
import {CONTENTFUL_ENTITY_OFFSET_LANDING} from "../../../constants/contentful";

export var pricePerCO2: number = 1;

// Calculate the contribution price based on the selected CO2 coverage of the user's footprint
export function calculatePrice(coverage: number) {
    return Number((footprint * pricePerCO2 * (coverage/100)).toFixed(1))
}

function ContributionContainer(props: ContributionContainerProps) {
    pricePerCO2 = props.pricePerCO2;

    const offsetsEntity = useContentfulEntity(CONTENTFUL_ENTITY_OFFSET_LANDING)

    return <div>
        <ul className='contributionContainer'>
            {/*Display all contribution options on desktop*/}
            <MediaQuery minWidth={768}>
                {contributionOptions.map((option: ContributionOptionData) => {
                    if (option.type === 'normal') {
                        return <li key={option.title}>
                            <ContributionOption title={offsetsEntity?.fields[option.title]} price={calculatePrice(option.amountOfCO2!)} amountOfCO2={option.amountOfCO2!} description={offsetsEntity?.fields[option.description]}/>
                        </li>
                    } else {
                        return <li key={option.title}>
                            <ContributionOptionAdjustable title={option.title} description={offsetsEntity?.fields[option.description]}/>
                        </li>
                    }
                })}
            </MediaQuery>
            {/*Display only the custom contribution option on mobile*/}
            <MediaQuery maxWidth={768}>
                {
                    contributionOptions.map((option: ContributionOptionData) => {
                        if (option.type === 'custom') {
                            return <li key={option.title}>
                                <ContributionOptionAdjustable title={option.title} description={offsetsEntity?.fields[option.description]}/>
                            </li>
                        } else return undefined
                    })
                }
            </MediaQuery>
        </ul>
        <div className='paymentLogos'>
            <PaymentLogos />
        </div>
    </div>;
}

interface ContributionContainerProps {
    pricePerCO2: number
}

export default ContributionContainer;
