import './Footer.scss'
import scone from '../../Images/sconeWhite.svg'
import { Facebook, Instagram, LinkedIn, Pinterest, Twitter } from '@material-ui/icons';
import { Link } from '@material-ui/core';
import { ROUTE_HOME, ROUTE_COMMUNITY_REGISTER } from '../../constants/routes';
import {useContentfulEntity} from "../../Hooks/contentful";
import {CONTENTFUL_ENTITY_BASIC_STRINGS} from "../../constants/contentful";

function Footer() {
    const basicEntity = useContentfulEntity(CONTENTFUL_ENTITY_BASIC_STRINGS)

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return <div className='footerContainer'>
        <div className='backToTop'>
            <div onClick={() => scrollToTop()}>Back to top ↑</div>
        </div>
        <div className='footer'>
            <div className='left'>
                <div className='title'><img src={scone} alt='Scone' /></div>
                <div className='subTitle'>Your path to zero</div>
                <div className='contact'>
                    <div className='bold'>Contact:</div>
                    <div>Lorem 244</div>
                    <div>3972 LK Brussel</div>
                    <div>+31 123 45 67 78</div>
                    <div>info@scone.com</div>
                </div>
            </div>
            <div className='right'>
                <div className='links'>
                    <div className='pageLinks'>
                        <div className='column'>
                            <Link href={ROUTE_HOME}><div>{basicEntity?.fields.home}</div></Link>
                            <div><a href='https://www.scone.ai/our-solution'>{basicEntity?.fields.about}</a></div>
                        </div>
                        <div className='column'>
                            {/*<div onClick={() => history.push(ROUTE_CALCULATOR)}>Calculator</div>*/}
                            <div><a href='https://www.scone.ai/news'>{basicEntity?.fields.news}</a></div>
                        </div>
                        <div className='column'>
                            <Link href={ROUTE_COMMUNITY_REGISTER}><div>{basicEntity?.fields.communityRegister}</div></Link>
                            <div><a href='https://www.scone.ai/contact-1'>{basicEntity?.fields.contact}</a></div>
                        </div>
                    </div>
                    <div className='socialMedia'>
                        <div>Follow us:</div>
                        <div className='icons'>
                            <Link className='show' href='https://www.linkedin.com/company/scone-ai/' color='inherit'><LinkedIn fontSize='large'/></Link>
                            <Link href='https://www.linkedin.com/company/scone-ai/' color='inherit'><Facebook fontSize='large'/></Link>
                            <Link href='https://www.linkedin.com/company/scone-ai/' color='inherit'><Instagram fontSize='large'/></Link>
                            <Link href='https://www.linkedin.com/company/scone-ai/' color='inherit'><Twitter fontSize='large'/></Link>
                            <Link href='https://www.linkedin.com/company/scone-ai/' color='inherit'><Pinterest fontSize='large'/></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='privacy'>
            <div>{basicEntity?.fields.disclaimer}</div>
            <div>{basicEntity?.fields.privacyStatement}</div>
            <div>{basicEntity?.fields.cookiePolicy}</div>
        </div>
        <div className='copyright'>&copy; 2021 Scone.ai</div>
    </div>
}

export default Footer;
