export const contributionOptions: ContributionOptionData[] = [
    {
        type: 'normal',
        title: 'tile1Title',
        amountOfCO2: 25,
        description: 'tile1Text'
    },
    {
        type: 'normal',
        title: 'tile2Title',
        amountOfCO2: 50,
        description: 'tile2Text'
    },
    {
        type: 'custom',
        title: 'tile3Title',
        description: 'tile3Text'
    },
]

export interface ContributionOptionData {
    type: 'normal' | 'custom';
    title: string;
    amountOfCO2?: number;
    description: string;
}