import NavBar from '../Shared/NavBar';
import './HomePage.scss'
import Footer from '../Shared/Footer';
import { APP_STORE, PLAY_STORE, ROUTE_OFFSETS_INTRO, ROUTE_COMMUNITY_REGISTER } from '../../constants/routes';
import { ApiRequestData } from '../../Interfaces/ApiRequestData';
import { Offset } from '../../Interfaces/Offset';
import { useOffsets } from '../../Hooks/useOffsets';
import OffsetContainerHomePage from './OffsetContainerHomePage';
import ApiRequestWrapper from '../Shared/ApiRequestWrapper';
import { Apple, Shop } from '@material-ui/icons';
import { Link } from '@material-ui/core';
import HomeHeader from './HomeHeader';
import UserExplanation from './UserExplanation';
import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import team from '../../Images/team.jpg'

function HomePage() {
    const [readMore, setReadMore] = useState<boolean>(false)
    const offsetData: ApiRequestData<Offset[], string> = useOffsets()
    const mobileRef = useRef<any>(undefined);

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const scrollToMobile = () => {
        if (mobileRef !== undefined && mobileRef.current !== undefined) {
            mobileRef.current.scrollIntoView({ behavior: "smooth", top: -20})
        }
    }

    return <div>
        <div className='homeContainer'>
            <NavBar />
            <div className='section'>
                <div className='homePage page'>
                    <HomeHeader/>

                    <div className='sconeExplanation'>
                        {!isMobile &&
                            <div className='info'>
                                <div className='title'>Who we are and why we do it</div>
                                <div className='subtitle'>Wij, bij Scone.ai, verbinden en bewegen mensen en gemeenschappen. De
                                    collectieve intelligentie van mensen & technologie stelt ons het best in staat om het hoofd
                                    te bieden aan de klimaatuitdaging.
                                </div>
                                <div className='subtitle'>Met onze projecten als Restore.eu, ForGood.eco en Scone.ai werd reeds
                                    uitstoot van miljoenen Ton CO₂ voorkomen. We kregen erkenning en prijzen van gerenommeerde
                                    organisaties. Doe met ons mee: met z’n allen op ‘Route CO₂ Neutraal’
                                </div>
                                <div className='awardsTitle'>Awards:</div>
                                <div className='awards'/>
                            </div>
                        }
                        {isMobile &&
                            <div className='title'>Who we are and why we do it</div>
                        }
                        <ul className='sconeOptions'>
                            <li><button onClick={() => scrollToMobile()}><div className='buttonTitle'>Voorkom CO₂</div><div className='buttonSubtitle'>Test Scone.ai Beta</div></button></li>
                            <li><Link href={ROUTE_COMMUNITY_REGISTER}><button><div className='buttonTitle'>Maak impact als Team</div><div className='buttonSubtitle'>Schrijf je nu in</div></button></Link></li>
                            <li><Link href={ROUTE_OFFSETS_INTRO}><button><div className='buttonTitle'>Steun dit Klimaatproject</div><div className='buttonSubtitle'>Compenseer CO₂</div></button></Link></li>
                        </ul>
                        {isMobile &&
                            <div>
                                {readMore &&
                                    <div>
                                        <div className='subtitle'>Wij, bij Scone.ai, verbinden en bewegen mensen en
                                            gemeenschappen. De
                                            collectieve intelligentie van mensen & technologie stelt ons het best in staat om
                                            het hoofd
                                            te bieden aan de klimaatuitdaging.
                                        </div>
                                        <div className='subtitle'>Met onze projecten als Restore.eu, ForGood.eco en Scone.ai
                                            werd reeds
                                            uitstoot van miljoenen Ton CO₂ voorkomen. We kregen erkenning en prijzen van
                                            gerenommeerde
                                            organisaties. Doe met ons mee: met z’n allen op ‘Route CO₂ Neutraal’
                                        </div>
                                    </div>
                                }
                                <div className='readMore' onClick={() => setReadMore(!readMore)}>{readMore ? 'Lees minder' : 'Lees meer'}</div>
                                <div className='awardsTitle'>Awards:</div>
                                <div className='awards'/>
                            </div>
                        }
                    </div>

                    <UserExplanation/>

                    <div className='team'>
                        <img src={team} alt='' />
                        <div className='teamInfo'>
                            <div className='title'>Organiseer de Team Challenge</div>
                            <div className='subtitle'>Set your Team goal and reduce CO₂ Footprint in a stimulating competition with colleagues and community. Connect, have fun and maximise positive impact</div>
                            <Link href={ROUTE_COMMUNITY_REGISTER}><button>Find out more</button></Link>
                            <div className='socialMediaContainer'>
                                <Link className='link' href='https://www.linkedin.com/in/erwinvanlaethem/' color='inherit'><LinkedInIcon fontSize='large'/><span>Erwin van Laethern</span></Link>
                                <Link className='link' href='https://www.linkedin.com/in/jan-willem-rombouts-a4211b1b/' color='inherit'><LinkedInIcon fontSize='large'/><span>Jan-Willem Rombouts</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section darker'>
                <div className='homePage page'>
                    <ApiRequestWrapper value={offsetData}>
                        {offsetData.data &&
                            <div className='offsetContainer'>
                                <OffsetContainerHomePage offsets={offsetData.data}/>
                            </div>
                        }
                    </ApiRequestWrapper>
                    <div className='mobileApp' ref={mobileRef}>
                        <div className='mobileContainer'>
                            <div className='mobileDemo absolute' />
                        </div>
                        <div className='info'>
                            <div className='title'>Jouw gids en reisgenoot op de weg naar CO₂ Neutraal</div>
                            <span className='subtitle bold'>Measure your CO₂ Footprint</span>
                            <span className='subtitle'>, get insights, tips and reminders of everyday actions that reduce your CO₂ footprint. Download your personal companion</span>
                            <div className='subtitle bold margin'>Download the ForGood App here:</div>
                            <div className='appButtons'>
                                <Link className='link' href={APP_STORE} color='inherit'><button><Apple /><span>Download</span></button></Link>
                                <Link className='link' href={PLAY_STORE} color='inherit'><button><Shop /><span>Download</span></button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </div>
}

export default HomePage
