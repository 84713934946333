import { useState } from 'react';
import { SetupIntent } from '../../Interfaces/SetupIntent';
import { CardElement, IbanElement, IdealBankElement, useElements, useStripe } from '@stripe/react-stripe-js';
import IdealLogo from '../../Images/PaymentOptions/idealLogo.svg'
import VisaLogo from '../../Images/PaymentOptions/visaLogo.svg'
import MasterCardLogo from '../../Images/PaymentOptions/masterCardLogo.svg'
import SepaLogo from '../../Images/PaymentOptions/sepaLogo.svg'
import BancontactLogo from '../../Images/PaymentOptions/bancontactLogo.svg'
import { UserProfileData } from '../../Interfaces/UserProfileData';
import { PaymentOptions } from '../../Enums/PaymentOptions';
import { useHistory } from 'react-router-dom';
import { ROUTE_OFFSETS_SUCCESS } from "../../constants/routes";
import { ApiRequestData } from '../../Interfaces/ApiRequestData';
import { useUserProfile } from '../../Hooks/useUserProfile';
import ApiRequestWrapper from '../Shared/ApiRequestWrapper';
import { useContentfulEntity } from "../../Hooks/contentful";
import { CONTENTFUL_ENTITY_BASIC_STRINGS, CONTENTFUL_ENTITY_OFFSET_LANDING } from "../../constants/contentful";
import { Dialog } from '@material-ui/core';
import { SconeCheckbox } from '../Utils/Checkbox';
import { InfoOutlined } from '@material-ui/icons';
import fail from '../../Images/fail.png'

function PaymentForm(props: PaymentFormProps) {
    const [paymentOption, setPaymentOption] = useState<PaymentOptions>(PaymentOptions.CREDIT_CARD);
    const [readMore, setReadMore] = useState<boolean>(false);
    const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
    const [validPayment, setValidPayment] = useState<boolean>(false);
    const [paymentError, setPaymentError] = useState<string | undefined>(undefined);
    const userProfileData: ApiRequestData<UserProfileData, string> = useUserProfile()

    const offsetsEntity = useContentfulEntity(CONTENTFUL_ENTITY_OFFSET_LANDING)
    const basicEntity = useContentfulEntity(CONTENTFUL_ENTITY_BASIC_STRINGS)

    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();

    const warningText = offsetsEntity?.fields.paymentSectionBankWarningText

    const handleSubscriptionSubmit = async (event: any) =>  {
        event.preventDefault();
        if (!stripe || !elements || !userProfileData.data) {
            setPaymentError('Some elements are not properly loaded')
            return;
        }
        let result;
        if (paymentOption === PaymentOptions.CREDIT_CARD) {
            const cardElement = elements.getElement(CardElement);
            if (!cardElement) {
                return;
            }
            result = await stripe.confirmCardSetup(props.setupIntent.secret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: userProfileData.data.firstName + ' ' + userProfileData.data.lastName,
                        email: userProfileData.data.email
                    },
                }
            });
        } else if (paymentOption === PaymentOptions.IDEAL) {
            const idealElement = elements.getElement(IdealBankElement);
            if (!idealElement) {
                return;
            }
            result = await stripe.confirmIdealSetup(props.setupIntent.secret, {
                payment_method: {
                    ideal: idealElement,
                    billing_details: {
                        name: userProfileData.data.firstName + ' ' + userProfileData.data.lastName,
                        email: userProfileData.data.email
                    },
                }
            });
        } else if (paymentOption === PaymentOptions.DIRECT_DEBIT) {
            const ibanElement = elements.getElement(IbanElement);
            if (!ibanElement) {
                return;
            }
            result = await stripe.confirmSepaDebitSetup(props.setupIntent.secret, {
                payment_method: {
                    sepa_debit: ibanElement,
                    billing_details: {
                        name: userProfileData.data.firstName + ' ' + userProfileData.data.lastName,
                        email: userProfileData.data.email
                    },
                }
            });
        } else if (paymentOption === PaymentOptions.BANCONTACT) {
            result = await stripe.confirmBancontactSetup(props.setupIntent.secret, {
                payment_method: {
                    billing_details: {
                        name: userProfileData.data.firstName + ' ' + userProfileData.data.lastName,
                        email: userProfileData.data.email
                    },
                }
            });
        }

        if (!result) {
            setPaymentError('No valid payment option was selected')
            return;
        }

        if (result.error) {
            setPaymentError(result.error.message)
        } else {
            history.push(ROUTE_OFFSETS_SUCCESS)
        }
    }

    function renderPaymentOption() {
        switch(paymentOption) {
            case PaymentOptions.CREDIT_CARD:
                return <div className={'input-container show'}>
                    <CardElement onChange={e => setValidPayment(!e.error)}/>
                    <label>{offsetsEntity?.fields.paymentSectionCardInfo}</label>
                </div>
            case PaymentOptions.IDEAL:
                return <div className={'input-container show'}>
                    <IdealBankElement onChange={e => setValidPayment(!e.error)}/>
                    <label>{offsetsEntity?.fields.paymentSectionSelectBank}</label>
                </div>
            case PaymentOptions.DIRECT_DEBIT:
                return <div className={'input-container show'}>
                    <IbanElement onChange={e => setValidPayment(!e.error)} options={{supportedCountries: ['SEPA']}}/>
                    <label>Iban</label>
                </div>
            case PaymentOptions.BANCONTACT:
            default:
                return null
        }
    }

    function changePaymentOption(option: PaymentOptions): void {
        setValidPayment(option === PaymentOptions.BANCONTACT);
        setPaymentOption(option);
    }

    return <div>
        <ApiRequestWrapper value={userProfileData}>
            <form className='checkoutForm' onSubmit={handleSubscriptionSubmit}>
                <div className='paymentOptions'>
                    <div className={'paymentOption' + (paymentOption === PaymentOptions.CREDIT_CARD ? ' selected' : '')} onClick={() => changePaymentOption(PaymentOptions.CREDIT_CARD)}>
                        <img src={MasterCardLogo} width='50px' height='50px' alt='MasterCard'/>
                        <img src={VisaLogo} width='50px' height='50px' alt='Visa'/>
                        <div>{basicEntity?.fields.creditCard}</div>
                        <input type="radio" id="cc" name="paymentOption" value={PaymentOptions.CREDIT_CARD} checked={paymentOption === PaymentOptions.CREDIT_CARD} readOnly={true}/>
                    </div>
                    <div className={'paymentOption' + (paymentOption === PaymentOptions.IDEAL ? ' selected' : '')} onClick={() => changePaymentOption(PaymentOptions.IDEAL)}>
                        <img src={IdealLogo} width='50px' height='50px' alt='Ideal'/>
                        <div>Ideal</div>
                        <input type="radio" id="ideal" name="paymentOption" value={PaymentOptions.IDEAL} checked={paymentOption === PaymentOptions.IDEAL} readOnly={true}/>
                    </div>
                    <div className={'paymentOption' + (paymentOption === PaymentOptions.DIRECT_DEBIT ? ' selected' : '')} onClick={() => changePaymentOption(PaymentOptions.DIRECT_DEBIT)}>
                        <img src={SepaLogo} width='50px' height='50px' alt='Iban'/>
                        <div>{basicEntity?.fields.directDebit}</div>
                        <input type="radio" id="iban" name="paymentOption" value={PaymentOptions.DIRECT_DEBIT} checked={paymentOption === PaymentOptions.DIRECT_DEBIT} readOnly={true}/>
                    </div>
                    <div className={'paymentOption' + (paymentOption === PaymentOptions.BANCONTACT ? ' selected' : '')} onClick={() => changePaymentOption(PaymentOptions.BANCONTACT)}>
                        <img src={BancontactLogo} width='50px' height='50px' alt='BanContact'/>
                        <div>Bancontact</div>
                        <input type="radio" id="bancontact" name="paymentOption" value={PaymentOptions.BANCONTACT} checked={paymentOption === PaymentOptions.BANCONTACT} readOnly={true}/>
                    </div>
                </div>
                {renderPaymentOption()}
                <div className='paymentOptionWarning'>
                    <SconeCheckbox checked={termsAndConditions} onChange={() => setTermsAndConditions(!termsAndConditions)}/>
                    <div>
                        {readMore ? warningText : warningText?.slice(0, 100) + '...'}
                        <span onClick={() => setReadMore(!readMore)}> {readMore ? basicEntity?.fields.readLess : basicEntity?.fields.readMore}</span>
                    </div>
                </div>
                <div className='paymentOptionWarning'>
                    <InfoOutlined style={{padding: '10px'}}/>
                    <div>{offsetsEntity?.fields.paymentSectionReassurance}</div>
                </div>
                <button disabled={!validPayment || !termsAndConditions}>{offsetsEntity?.fields.paymentSectionButton}</button>
            </form>
        </ApiRequestWrapper>
        <Dialog open={!!paymentError} onClose={() => setPaymentError(undefined)}>
            <img src={fail} alt='fail'/>
            <div className='errorDialog'>
                <div className='title'>Something went wrong</div>
                <div className='errorInfo'>{paymentError}</div>
            </div>
        </Dialog>
    </div>
}

interface PaymentFormProps {
    setupIntent: SetupIntent;
    authenticationToken: string;
}

export default PaymentForm;
