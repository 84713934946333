export const ROUTE_HOME = '/'
export const ROUTE_COMMUNITY_REGISTER = '/community-register'

export const ROUTE_OFFSETS_INTRO = '/offsets/intro'
export const ROUTE_OFFSETS_PAYMENT = '/offsets/payment'
export const ROUTE_OFFSETS_SUCCESS = '/offsets/success'

export const ROUTE_CALCULATOR = '/calculator'

export const APP_STORE = 'https://play.google.com/store/apps/details?id=forgood.be.forgood'
export const PLAY_STORE = 'https://apps.apple.com/nl/app/for-good/id1045549833'
