export enum TeamType {
    COMPANY = 'Company',
    CITY = 'City',
    COMMUNITY = 'Community',
}

export const teamTypes = [
    TeamType.COMMUNITY,
    TeamType.CITY,
    TeamType.COMPANY,
]