import '../Offset/SubscriptionPage.scss'
import './PaymentPage.scss'
import ContributionOption from '../Shared/ContributionOption';
import PageHeader from '../Shared/NavBar';
import stripeLogo from '../../Images/PaymentOptions/stripeBlurple.svg'
import kboLogo from '../../Images/PaymentOptions/kboLogo.png'
import { Lock } from '@material-ui/icons';
import Checkout from './Checkout';
import {useContentfulEntity} from "../../Hooks/contentful";
import {CONTENTFUL_ENTITY_OFFSET_LANDING} from "../../constants/contentful";
import { useRecoilState } from 'recoil';
import { paymentObjectState } from '../../store';

function PaymentPage() {
    const paymentObject = useRecoilState(paymentObjectState)[0];
    const offsetsEntity = useContentfulEntity(CONTENTFUL_ENTITY_OFFSET_LANDING)

    return paymentObject ?
            <div>
                <PageHeader />
                <div className='paymentPageContainer page'>
                    <div className='paymentInformation'>
                        <div className='paymentTitle'>{offsetsEntity?.fields.paymentPageTitle}</div>
                        <div className='contributionInfo'>
                            <ContributionOption
                                title={paymentObject.title}
                                amountOfCO2={paymentObject.amountOfCO2}
                                price={paymentObject.price}
                                description={paymentObject.description}
                                showButton={false}
                                showDecimals={true}
                            />
                        </div>
                        <div className='securityInfo'>
                            <div className='securityText'>
                                <Lock fillOpacity={0.25}/>
                                <div>{offsetsEntity?.fields.paymentPageReassurance}</div>
                            </div>
                            <div className='logos'>
                                <a href='https://stripe.com/nl/privacy' target='_blank' rel="noreferrer"><img className='stripeLogo' src={stripeLogo} alt='Stripe badge'/></a>
                                <a href='https://kbopub.economie.fgov.be/kbopub/toonondernemingps.html?ondernemingsnummer=766600502' target='_blank' rel="noreferrer"><img className='kboLogo' src={kboLogo} alt='KBO badge'/></a>
                            </div>
                        </div>
                    </div>
                    <div className='formContainer'>
                        <Checkout price={paymentObject.price} email={paymentObject.email} firstName={paymentObject.firstName} lastName={paymentObject.lastName}/>
                    </div>
                </div>
            </div>
        : null
}

export default PaymentPage
