import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import AccountForm from './AccountForm';
import PaymentForm from './PaymentForm';
import { Elements } from '@stripe/react-stripe-js';
import { SetupIntent } from '../../Interfaces/SetupIntent';
import './Checkout.scss';
import PaymentLogos from '../Shared/PaymentLogos';
import { ApiRequestData } from '../../Interfaces/ApiRequestData';
import { useSetupIntent } from '../../Hooks/useSetupIntent';
import ApiRequestWrapper from '../Shared/ApiRequestWrapper';
import {useContentfulEntity} from "../../Hooks/contentful";
import {CONTENTFUL_ENTITY_OFFSET_LANDING} from "../../constants/contentful";
import { useRecoilState } from 'recoil';
import { authenticationTokenState } from '../../store';

function Checkout(props: CheckoutProps) {
    const authenticationToken = useRecoilState(authenticationTokenState)[0]
    const setupIntentData: ApiRequestData<SetupIntent, string> = useSetupIntent(props.price)
    const offsetsEntity = useContentfulEntity(CONTENTFUL_ENTITY_OFFSET_LANDING)

    return <div>
        <Accordion classes={{root: 'accordion'}} expanded={true}>
            <AccordionSummary><b>{offsetsEntity?.fields.accountSectionTitle}</b></AccordionSummary>
            <AccordionDetails>
                <AccountForm email={props.email} firstName={props.firstName} lastName={props.lastName}/>
            </AccordionDetails>
        </Accordion>
        <Accordion classes={{root: 'accordion'}} expanded={!!authenticationToken}>
            <AccordionSummary>
                <div className='checkoutPaymentHeader'>
                    <div><b>{offsetsEntity?.fields.paymentSectionTitle}</b></div>
                    {!authenticationToken &&
                        <div>
                            <span>Veilig betalen kan bij ons met: </span>
                            <div className='checkoutPaymentLogos'>
                                <PaymentLogos/>
                            </div>
                        </div>
                    }
                </div>
            </AccordionSummary>
            {!!authenticationToken &&
                <AccordionDetails>
                    <ApiRequestWrapper value={setupIntentData}>
                        {setupIntentData.data?.stripePromise &&
                            <Elements stripe={setupIntentData.data.stripePromise}>
                                <PaymentForm setupIntent={setupIntentData.data} authenticationToken={authenticationToken}/>
                            </Elements>
                        }
                    </ApiRequestWrapper>
                </AccordionDetails>
            }
        </Accordion>
    </div>
}

interface CheckoutProps {
    price: number;
    email?: string;
    firstName?: string;
    lastName?: string;
}

export default Checkout
