import { Offset } from '../../Interfaces/Offset';
import './SubscriptionPage.scss'
import ContributionContainer from './Contribution/ContributionContainer';
import OffsetContainer from './OffsetContainer';
import QaContainer from './Q&A/QaContainer';
import { useOffsets } from '../../Hooks/useOffsets';
import { ApiRequestData } from '../../Interfaces/ApiRequestData';
import ApiRequestWrapper from '../Shared/ApiRequestWrapper';
import {useContentfulEntity} from "../../Hooks/contentful";
import {CONTENTFUL_ENTITY_OFFSET_LANDING} from "../../constants/contentful";
import NavBar from '../Shared/NavBar';
import Footer from '../Shared/Footer';

export const footprint: number = Number(new URLSearchParams(window.location.search).get('footprint') ?? 8);
export const email: string | undefined = new URLSearchParams(window.location.search).get('email') ?? undefined;
export const firstName: string | undefined = new URLSearchParams(window.location.search).get('firstName') ?? undefined;
export const lastName: string | undefined = new URLSearchParams(window.location.search).get('lastName') ?? undefined;

function SubscriptionPage() {
    const offsetData: ApiRequestData<Offset[], string> = useOffsets()

    const offsetsEntity = useContentfulEntity(CONTENTFUL_ENTITY_OFFSET_LANDING)
    const pricePerCO2 = (offsetData.data ? offsetData.data
                .map(offset => offset.cost_euroPertCO2 * offset.allocation)
                .reduce((a, b) => a + b, 0) / 100 / 12 : 0)

    return <div>
        <NavBar showPartner={true}/>
        <div className='container page'>
            <div className='subscriptionPage'>
                <ApiRequestWrapper value={offsetData}>
                    {offsetData.data &&
                        <div>
                            <div className='title'>
                                {offsetsEntity?.fields.title
                                    .replace('{FOOTPRINT}', footprint)
                                    .replace('{MIN}', Math.ceil(pricePerCO2 * footprint * 0.25))
                                }
                            </div>
                            <div className='pageGroup'>
                                <ContributionContainer pricePerCO2={pricePerCO2}/>
                            </div>
                            <div className='pageGroup'>
                                <OffsetContainer offsets={offsetData.data}/>
                            </div>
                        </div>
                    }
                </ApiRequestWrapper>
                <div className='pageGroup'>
                    <QaContainer />
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}

export default SubscriptionPage
