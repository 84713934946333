import { useState } from 'react';
import { Offset } from '../../Interfaces/Offset';
import './OffsetContainer.scss'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import {useContentfulEntity} from "../../Hooks/contentful";
import {CONTENTFUL_ENTITY_OFFSET_LANDING} from "../../constants/contentful";

function OffsetContainer(props: OffsetContainerProps) {
    const [selectedOffset, setSelectedOffset] = useState<Offset>(props.offsets[0])
    const [shouldAutoplay, setShouldAutoplay] = useState<boolean>(true)
    const [showFullDescription, setShowFullDescription] = useState<boolean>(false)

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const offsetsEntity = useContentfulEntity(CONTENTFUL_ENTITY_OFFSET_LANDING)

    return <div className='offsetContainer'>
        <div className='offsetDescription'>
            <div className='offsetTitle'>
                {offsetsEntity?.fields.offsetsTitle}
            </div>
            <div className='offsetInfo'>
                {offsetsEntity?.fields.offsetsText}
            </div>
            <div className='offsetList'>
                <ul>
                    {props.offsets.map(offset => {
                        return <li
                            key={offset.id}
                            onClick={() => {setSelectedOffset(offset); setShouldAutoplay(false)}}
                            className={selectedOffset.id === offset.id ? 'selectedOffset' : ''}
                        >
                            <span className='offsetTechnologies'>{offset.technologies}</span>
                            <span className='offsetAllocation'>{offset.allocation}%</span>
                        </li>
                        })
                    }
                </ul>
            </div>
        </div>
        <div className='offsetImageContainer'>
            <Carousel
                autoPlay={shouldAutoplay && !isMobile}
                interval={5000}
                showStatus={false}
                infiniteLoop={true}
                showThumbs={false}
                swipeable={true}
                showArrows={false}
                selectedItem={props.offsets.indexOf(selectedOffset)}
                onChange={(index:number) => setSelectedOffset(props.offsets[index])}>
                {props.offsets.map(offset => {
                    return <div key={offset.id}>
                        <img className='cover' src={offset.imageUrl} alt={offset.technologies}/>
                        <MediaQuery minWidth={768}>
                            <div className='sconeLegend'>
                                <p className='legendTitle'>{offset.name}</p>
                                <p className='legendDescription'>{
                                    showFullDescription
                                        ? offset.description[0]
                                        : offset.description[0].match(/^(.*?)[.?!]\s/)?.[0]
                                }</p>
                                <span className='legendMoreOrLess' onClick={() => setShowFullDescription(!showFullDescription)}>{!showFullDescription ? 'Read More' : 'Read Less'}</span>
                            </div>
                        </MediaQuery>
                    </div>
                })
                }
            </Carousel>
        </div>
        <MediaQuery maxWidth={768}>
            <div>
                <div className='mobileOffsetTitle'>{selectedOffset.name}</div>
                <div className='mobileOffsetDescription'>{selectedOffset.description[0]}</div>
            </div>
        </MediaQuery>
    </div>;
}

interface OffsetContainerProps {
    offsets: Offset[],
}

export default OffsetContainer
