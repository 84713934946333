import { Checkbox, withStyles } from '@material-ui/core';

export const SconeCheckbox = withStyles({
    root: {
        color: '#347473',
        '&$checked': {
            color: '#347473',
        },
    },
    checked: {},
})(Checkbox);