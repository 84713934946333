import { useEffect, useState } from 'react';
import { ApiRequestData, ApiRequestStatus } from '../Interfaces/ApiRequestData';
import { UserProfileData } from '../Interfaces/UserProfileData';
import { UserApiService } from '../Services/UserApiService';
import { useRecoilState } from 'recoil';
import { authenticationTokenState } from '../store';

export function useUserProfile(): ApiRequestData<UserProfileData, string> {
    const [user, setUser] = useState<undefined | UserProfileData>(undefined)
    const [error, setError] = useState<undefined | string>(undefined)
    const [status, setStatus] = useState<ApiRequestStatus>(ApiRequestStatus.INIT)

    const authenticationToken = useRecoilState(authenticationTokenState)[0]

    useEffect(() => {
        (async () => {
            try {
                if (!authenticationToken) {
                    throw new Error('No authentication token set')
                }
                setStatus(ApiRequestStatus.PENDING);
                const response = await UserApiService.getUserProfile(authenticationToken);
                if (!response.ok) {
                    throw new Error('Server responded with a ' + response.status.toString() + '. Check your internet connection or try refreshing your browser!')
                }
                const userProfile = await response.json();
                setUser(userProfile);
                setStatus(ApiRequestStatus.SUCCESS);
            } catch(error) {
                setError(error.message)
                setStatus(ApiRequestStatus.ERROR);
            }
        })()
    }, [authenticationToken])

    return {
        status: status,
        data: user,
        error: error
    }
}