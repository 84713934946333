import { useEffect, useState } from 'react';
import { ApiRequestData, ApiRequestStatus } from '../Interfaces/ApiRequestData';
import { Offset } from '../Interfaces/Offset';
import { OffsettingApiService } from '../Services/OffsettingApiService';

export function useOffsets(): ApiRequestData<Offset[], string> {
    const [offsets, setOffsets] = useState<undefined | Offset[]>(undefined)
    const [error, setError] = useState<undefined | string>(undefined)
    const [status, setStatus] = useState<ApiRequestStatus>(ApiRequestStatus.INIT)

    useEffect(() => {
        (async () => {
            try {
                setStatus(ApiRequestStatus.PENDING);
                const response = await OffsettingApiService.getOffsets();
                if (!response.ok) {
                    throw new Error('Server responded with a ' + response.status.toString() + '. Check your internet connection or try refreshing your browser!')
                }

                const offsets = await response.json();
                setOffsets(offsets);
                setStatus(ApiRequestStatus.SUCCESS);
            } catch(error) {
                setError(error.message)
                setStatus(ApiRequestStatus.ERROR);
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        status: status,
        data: offsets,
        error: error
    }
}