import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import SubscriptionPage from './Components/Offset/SubscriptionPage';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PaymentPage from './Components/Payment/PaymentPage';
import TransactionCompletePage from './Components/Payment/TransactionCompletePage';
import {
    ROUTE_CALCULATOR, ROUTE_HOME,
    ROUTE_OFFSETS_INTRO,
    ROUTE_OFFSETS_PAYMENT,
    ROUTE_OFFSETS_SUCCESS, ROUTE_COMMUNITY_REGISTER
} from "./constants/routes";
import {Calculator} from "./Components/Calculator/Calculator";
import SignUpPage from './Components/SignUp/SignUpPage';
import HomePage from './Components/Home/HomePage';
import ScrollToTop from './Hooks/ScrollToTop';
import { RecoilRoot } from "recoil";

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <RecoilRoot>
              <React.Suspense fallback={<div>Loading...</div>}>
                  <ScrollToTop />
                  <Switch>
                      <Route path={ROUTE_OFFSETS_SUCCESS}>
                          <TransactionCompletePage />
                      </Route>
                      <Route path={ROUTE_CALCULATOR}>
                          <Calculator />
                      </Route>
                      <Route path={ROUTE_OFFSETS_INTRO}>
                          <SubscriptionPage />
                      </Route>
                      <Route path={ROUTE_OFFSETS_PAYMENT}>
                          <PaymentPage />
                      </Route>
                      <Route path={ROUTE_COMMUNITY_REGISTER}>
                          <SignUpPage />
                      </Route>
                      <Route path={ROUTE_HOME}>
                          <HomePage />
                      </Route>
                  </Switch>
              </React.Suspense>
          </RecoilRoot>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
