export interface ApiRequestData<S, E> {
    status: ApiRequestStatus;
    data?: S;
    error?: E;
}

export enum ApiRequestStatus {
    INIT = 'INIT',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERR'
}