import { useEffect, useState } from 'react';
import { ApiRequestData, ApiRequestStatus } from '../Interfaces/ApiRequestData';
import { OffsettingApiService } from '../Services/OffsettingApiService';
import { SetupIntent } from '../Interfaces/SetupIntent';
import { loadStripe } from '@stripe/stripe-js';
import { useRecoilState } from 'recoil';
import { authenticationTokenState } from '../store';

export function useSetupIntent(amount: number): ApiRequestData<SetupIntent, string> {
    const [setupIntent, setSetupIntent] = useState<undefined | SetupIntent>(undefined)
    const [error, setError] = useState<undefined | string>(undefined)
    const [status, setStatus] = useState<ApiRequestStatus>(ApiRequestStatus.INIT)

    const authenticationToken = useRecoilState(authenticationTokenState)[0]

    useEffect(() => {
        (async () => {
            try {
                if (!authenticationToken) {
                    throw new Error('No authentication token set')
                }
                setStatus(ApiRequestStatus.PENDING);
                const response = await OffsettingApiService.getSetupIntent(authenticationToken, amount);
                if (!response.ok) {
                    throw new Error('Server responded with a ' + response.status.toString() + '. Check your internet connection or try refreshing your browser!')
                }
                let setupIntent: SetupIntent = await response.json();
                setupIntent.stripePromise = loadStripe(setupIntent.publishable);
                setSetupIntent(setupIntent);
                setStatus(ApiRequestStatus.SUCCESS);
            } catch(error) {
                setError(error.message)
                setStatus(ApiRequestStatus.ERROR);
            }
        })()
    }, [authenticationToken, amount])

    return {
        status: status,
        data: setupIntent,
        error: error
    }
}