import './UserExplanation.scss'
import { useState } from 'react';
import teacher from '../../Images/teacher.svg'

function UserExplanation() {
    const [index, setIndex] = useState<number>(0)

    const info = [
        {
            imageUrl: teacher,
            title: 'Versnel je route naar CO₂ neutraal',
            description: 'Voorkom CO₂ en verminder je persoonlijke voetafdruk. Bepaal jouw ecologische voetafdruk, krijg inzicht en tips over alledaagse dingen waardoor je CO2 uitstoot voorkomt en je voetafdruk al snel verlaagt.',
        },
        {
            imageUrl: teacher,
            title: 'Your trusted companion on your Path to Zero',
            description: 'Nascetur amet, eget pulvinar nec amet, dui pulvinar. Duis hendrerit consectetur euismod viverra malesuada.',
        },
        {
            imageUrl: teacher,
            title: 'Compensate your CO₂ footprint',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nascetur amet, eget pulvinar nec amet, dui pulvinar. Duis hendrerit consectetur euismod viverra malesuada.',
        }
    ]

    return <div className='userExplanation'>
        <div className='yellowSquare'/>
        <div className='userInfo'>
            <div>
                <div className='title'>{info[index].title}</div>
                <div className='subtitle'>{info[index].description}</div>
            </div>
            <div className='buttons'>
                <div className='control-dots'>
                    <div className={'dot' + (index === 0 ? ' selected' : '')} onClick={() => setIndex(0)}/>
                    <div className={'dot' + (index === 1 ? ' selected' : '')} onClick={() => setIndex(1)}/>
                    <div className={'dot' + (index === 2 ? ' selected' : '')} onClick={() => setIndex(2)}/>
                </div>
                <div className='next' onClick={() => setIndex((index + 1) % 3)}>Next &#x2192;</div>
            </div>
        </div>
        <div className='imageContainer'>
            <div className='image' style={{backgroundImage: `url('${info[index].imageUrl}')`}}/>
        </div>
    </div>
}

export default UserExplanation