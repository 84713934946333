import './TransactionCompletePage.scss'
import { ROUTE_HOME } from '../../constants/routes';
import sconeLogo from '../../Images/scone.svg'
import mediahuisLogo from '../../Images/Mediahuis.svg'
import NavBar from '../Shared/NavBar';
import Footer from '../Shared/Footer';
import success from '../../Images/success.png'
import { useMediaQuery } from 'react-responsive';
import { Link } from '@material-ui/core';

function TransactionCompletePage() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    return <div>
        <NavBar showPartner={true}/>
        <div className='success'>
            <div className='title'>Transaction completed!</div>
            {isMobile && <img src={success} alt='success'/>}
            <div className='subtitle'>Check your email to complete your subscription</div>
            <div className='options'>
                <Link href={ROUTE_HOME}><div className='scone'>
                    <img src={sconeLogo} alt='scone'/>
                    <div className='label'>Click here to return to Scone</div>
                </div></Link>
                {!isMobile && <img src={success} alt='success'/>}
                <div className='mediahuis'><a href='https://www.mediahuis.nl/'>
                    <img src={mediahuisLogo} alt='mediaHuis'/>
                    <div className='label'>Click here to return to Mediahuis</div>
                </a></div>
            </div>
        </div>
        <Footer/>
    </div>
}

export default TransactionCompletePage