import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import './QaContainer.scss';
import MediaQuery from 'react-responsive';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import {useContentfulEntity} from "../../../Hooks/contentful";
import {CONTENTFUL_ENTITY_OFFSET_LANDING} from "../../../constants/contentful";

function QaContainer() {
    const offsetsEntity = useContentfulEntity(CONTENTFUL_ENTITY_OFFSET_LANDING)

    return <div className='qaContainer'>
        <div className='qaLeft'>
            <div className='qaTitle'>
                {offsetsEntity?.fields.questionsTitle}
            </div>
            <MediaQuery minWidth={768}>
                <EmojiObjectsIcon className='lightBulb' color='inherit'/>
            </MediaQuery>
        </div>
        <div className='qaRight'>
            {offsetsEntity?.fields.questions.map((item: {fields: any}, idx: number) => {
                return <Accordion classes={{root: 'accordion'}} elevation={0} key={idx}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <div className='qaQuestion'>{item.fields.question}</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='qaAnswer'>{item.fields.answer}</div>
                    </AccordionDetails>
                </Accordion>
                })
            }
        </div>
    </div>;
}

export default QaContainer
