export enum TeamSizeType {
    LESS_THAN_HUNDRED = '< 100',
    HUNDRED_TO_FIVE_HUNDRED = '100 - 500',
    MORE_THAN_FIVE_HUNDRED = '> 500',
}

export const teamSizeTypes = [
    TeamSizeType.LESS_THAN_HUNDRED,
    TeamSizeType.HUNDRED_TO_FIVE_HUNDRED,
    TeamSizeType.MORE_THAN_FIVE_HUNDRED,
]