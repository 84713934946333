import { API_USER_PROFILE, API_USER_SIGNUP } from "../constants/urls";

export abstract class UserApiService {
    public static getUserProfile(authenticationToken: string): Promise<Response> {
        return fetch(API_USER_PROFILE, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authenticationToken,
            },
        })
    }

    public static createAccount(firstName: string, lastName: string, email: string): Promise<Response> {
        return fetch(API_USER_SIGNUP, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: email,
                firstName: firstName,
                lastName: lastName,
                language: navigator.language.split('-')[0] || 'en'
            })
        })
    }

    public static login(email: string, password: string): Promise<Response> {
        return fetch(process.env.REACT_APP_ROOT_API_URL + '/user/auth/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email: email, password: password, sentianceId: ''})
        })
    }
}