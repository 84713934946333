import {selectorFamily, useRecoilValue} from "recoil";
import {createClient, CreateClientParams, Entry} from "contentful";
import {CONTENTFUL_SPACE} from "../constants/contentful";

const params: CreateClientParams = {
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ?? '',
    space: CONTENTFUL_SPACE,
}

if (process.env.REACT_APP_CONTENTFUL_USE_PREVIEW === 'true') {
    params.host = 'preview.contentful.com'
}

const contentfulClient = createClient(params)

const language = navigator.language.split('-')[0]
let locale: string

switch (language) {
    case 'nl':
        locale = 'nl-NL'
        break;
    default:
        locale = 'en-US'
        break
}

async function getContentfulEntity<T>(entityId: string): Promise<Entry<T>>{
    return contentfulClient.getEntry(entityId, {
        include: 2,
        locale,
    });
}

const contentfulEntities = selectorFamily({
    key: 'ContentfulEntities',
    get: (entityId: string) => async () => {
        const entity = await getContentfulEntity(entityId);
        // TODO error handling...  Throw an exception.  But need something to catch it.
        return entity
    },
});

export function useContentfulEntity(entityId: string): Entry<any> | null {
    return useRecoilValue(contentfulEntities(entityId));
}
