import { Card, withStyles } from '@material-ui/core';

export const SconeCard = withStyles({
    root: {
        borderRadius: '16px',
        background: '#E5E5E5',
        border: '2px solid #A0B3BD',
        boxSizing: 'border-box',
        padding: '20px',
        transition: 'all 0.15s ease-in-out',
    }
})(Card);