import { APP_STORE, PLAY_STORE, ROUTE_COMMUNITY_REGISTER } from '../../constants/routes';
import { Link } from '@material-ui/core';
import { Apple, Shop } from '@material-ui/icons';
import './HomeHeader.scss'

function HomeHeader() {
    return <div className='homeHeader'>
        <div className='left'>
            <div className='title'>Personaliseer jouw ‘Route CO₂ Neutraal’</div>
            <div className='subtitle'>Voor jezelf, jouw stad of jouw bedrijf</div>
            <Link href={ROUTE_COMMUNITY_REGISTER}><button className='signUpButton'>Schrijf je in voor de Beta Test</button></Link>
            <div className='appButtons'>
                <Link className='link' href={APP_STORE} color='inherit'><button><Apple /><span>Download</span></button></Link>
                <Link className='link' href={PLAY_STORE} color='inherit'><button><Shop /><span>Download</span></button></Link>
            </div>
        </div>
        <div className='right'>
            <div className='greenCircle absolute' />
            <div className='mobileDemo absolute' />
        </div>
    </div>
}

export default HomeHeader
