import { atom } from 'recoil';
import { PaymentObject } from './Interfaces/PaymentObject';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist()

export const authenticationTokenState = atom<string | undefined>({
    key: 'authenticationToken',
    default: undefined,
    effects_UNSTABLE: [persistAtom]
});

export const paymentObjectState = atom<PaymentObject | undefined>({
    key: 'paymentObject',
    default: undefined,
    effects_UNSTABLE: [persistAtom]
});