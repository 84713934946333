import { ChangeEvent, useState } from 'react';
import '../../Shared/ContributionOption.scss'
import { SconeCard } from '../../Utils/Card';
import { SconeSlider } from '../../Utils/Slider';
import { contributionOptions } from '../../../constants/ContributionOptions';
import { useHistory } from 'react-router-dom';
import { email, firstName, lastName } from '../SubscriptionPage';
import { useMediaQuery } from 'react-responsive';
import { calculatePrice } from './ContributionContainer';
import {ROUTE_OFFSETS_PAYMENT} from "../../../constants/routes";
import {useContentfulEntity} from "../../../Hooks/contentful";
import {CONTENTFUL_ENTITY_OFFSET_LANDING} from "../../../constants/contentful";
import { useRecoilState } from 'recoil';
import { paymentObjectState } from '../../../store';

function ContributionOptionAdjustable(props: ContributionOptionAdjustableProps) {
    const [amountOfCO2, setAmountOfCO2] = useState<number>(100);
    const [price, setPrice] = useState<number>(calculatePrice(amountOfCO2));
    const [title, setTitle] = useState<string>(props.title);

    const setPaymentObject = useRecoilState(paymentObjectState)[1];

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const history = useHistory()
    const offsetsEntity = useContentfulEntity(CONTENTFUL_ENTITY_OFFSET_LANDING)

    function handleSliderChange(event: ChangeEvent<{}>, newValue: number | number[]) {
        if (typeof newValue !== 'number') {
            newValue = newValue[0];
        }
        setAmountOfCO2(newValue);
        setPrice(calculatePrice(newValue))
        if (isMobile) {
            let options = contributionOptions.filter(option => option.amountOfCO2 && option.amountOfCO2 > amountOfCO2)
            setTitle(options.length > 0 ? options[0].title : props.title);
        }
    }

    function goToPaymentPage() {
        setPaymentObject({
            price: price,
            title: props.title,
            amountOfCO2: amountOfCO2,
            description: props.description,
            email: email,
            firstName: firstName,
            lastName: lastName,
        })
        history.push(ROUTE_OFFSETS_PAYMENT);
    }

    return <SconeCard className='contributionOptionContainer'>
        <div className='optionTitle'>{offsetsEntity?.fields[title]}</div>
        <div className='optionSubTitle'>{offsetsEntity?.fields.tileCoverTextTemplate.replace('{AMOUNT}', amountOfCO2)}</div>
        {isMobile &&
            <div className='optionPriceContainer'>
                <span className='optionPrice'>&euro;{price.toFixed(2)}</span>
                <span className='pm mobile'>/maand</span>
            </div>
        }
        <div className='optionSlider'>
            <SconeSlider
                min={isMobile ? 1 : 51}
                max={150}
                value={amountOfCO2}
                onChange={handleSliderChange}
                valueLabelDisplay={isMobile ? 'off' : 'on'}
                valueLabelFormat={value => <div>&euro;{price.toFixed(2)} p/m</div>}
            />
        </div>
        <div className='optionDescription'>{props.description}</div>
        <div className='optionExtra'>{offsetsEntity?.fields.tileCallToAction}</div>
        <button className='optionButton' onClick={goToPaymentPage}>{offsetsEntity?.fields.subscribeButtonText}</button>
    </SconeCard>
}

interface ContributionOptionAdjustableProps {
    title: string;
    description: string;
}

export default ContributionOptionAdjustable
