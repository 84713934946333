import NavBar from '../Shared/NavBar';
import './SignUpPage.scss'
import { TeamType, teamTypes } from '../../Enums/TeamType';
import React, { useState } from 'react';
import { validateEmail } from '../Utils/ValidateEmail';
import Footer from '../Shared/Footer';
import { SconeCheckbox } from '../Utils/Checkbox';
import { TeamSizeType, teamSizeTypes } from '../../Enums/TeamSizeType';
import emailjs from 'emailjs-com'
import ReCAPTCHA from 'react-google-recaptcha';
import { CircularProgress, Dialog, Link } from '@material-ui/core';
import fail from '../../Images/fail.png';
import success from '../../Images/success.png';
import { ROUTE_HOME } from '../../constants/routes';


function SignUpPage() {
    const [email, setEmail] = useState<string>('');
    const [emailValidation, setEmailValidation] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [intent, setIntent] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [teamType, setTeamType] = useState<TeamType | undefined>(undefined)
    const [teamSizeType, setTeamSizeType] = useState<TeamSizeType | undefined>(undefined)
    const [captcha, setCaptcha] = useState<string | null>(null)
    const [isClicked, setIsClicked] = useState<boolean>(false)
    const [status, setStatus] = useState<'success' | 'failed' | undefined>(undefined)

    const handleAccountSubmit = async (event: any) => {
        event.preventDefault();
        if (!canSubmit()) {
            return;
        }
        setIsClicked(true);
        // TODO: Replace this with a real backend
        try {
            const response = await emailjs.send(
                'contact_service',
                'community_sign_up',
                {
                    name,
                    email,
                    intent,
                    companyName,
                    teamType,
                    phoneNumber,
                    teamSizeType,
                    'g-recaptcha-response': captcha
                },
                'user_vHqUX2BurB3IbMv0Sw50u'
            )
            setStatus(response.status === 200 ? 'success' : 'failed')
        } catch (error) {
            setStatus('failed')
        }
        setIsClicked(false)
    }

    function validateEmailField() {
        let error = validateEmail(email) ? '' : 'Not a valid email';
        setEmailValidation(error)
    }

    function canSubmit() {
        return name && email && !emailValidation && intent && captcha;
    }

    return <div className='signUpContainer'>
        <NavBar />
        <div className='signUpPage page'>
            <div className='content'>
                <div className='title'>Maak impact met je team en schrijf je hier in voor de team CO  challenge</div>
                <div className='subTitle'>Organiseer een CO₂ Challenge voor jouw Team, Gemeente of Bedrijf. Verbind en stimuleer elkaar, daag elkaar uit! Maak nog meer impact door gezamenlijk de ecologische voetafdruk te verkleinen.</div>
                <form onSubmit={handleAccountSubmit} className='signUpForm'>
                    <div className="input-container">
                        <label>Spreek mij aub aan als</label>
                        <input className={name && 'valid'} type='text' name='name' placeholder='Fill in your name' value={name} onChange={e => setName(e.target.value)}/>
                    </div>
                    <div className="input-container">
                        <label>Mijn mailadres is</label>
                        <input className={emailValidation ? 'error' : (email && 'valid')} name='email' type='text' placeholder='Fill in your email' value={email} onChange={e => setEmail(e.target.value)} onBlur={validateEmailField}/>
                    </div>
                    <div className="input-container">
                        <label>Wat ik wil weten over de Team CO₂ Challenge is</label>
                        <textarea className={intent && 'valid'} name='intent' placeholder='Fill in your intention to participate' value={intent} onChange={e => setIntent(e.target.value)}/>
                    </div>
                    <div className='optional'>Optioneel</div>
                    <div className="input-container">
                        <label>Ik vertegenwoordig</label>
                        <input type='text' placeholder='Name of the ...' value={companyName} onChange={e => setCompanyName(e.target.value)}/>
                    </div>
                    <div className="input-container">
                        <label> </label>
                        <div className='checkboxes'>
                            {teamTypes.map(type => <div key={type}><SconeCheckbox checked={type === teamType} onChange={() => setTeamType(teamType === type ? undefined : type)}/>{type}</div>)}
                        </div>
                    </div>
                    <div className="input-container">
                        <label>Mijn telefoonnummer</label>
                        <input type='text' placeholder='Fill in you phone number' value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}/>
                    </div>
                    <div className="input-container">
                        <label>Hoeveel deelnemers wil je meenemen?</label>
                        <div className='buttons'>
                            {teamSizeTypes.map(type => <button key={type} className={type === teamSizeType ? 'checked' : ''} onClick={() => setTeamSizeType(teamSizeType === type ? undefined : type)}>{type}</button>)}
                        </div>
                    </div>
                    <div className="input-container">
                        <label> </label>
                        <div className='captcha'>
                            <ReCAPTCHA size={'compact'} sitekey={'6LfRynIcAAAAAL-lYDiUuMV8DaA-bX8S1S3Yb4Qu'} onChange={setCaptcha}/>
                        </div>
                    </div>
                    <div className="input-container">
                        <label> </label>
                        <button className='submitButton' disabled={!canSubmit()}>{isClicked ? <CircularProgress />: 'Join us'}</button>
                    </div>
                </form>
            </div>
        </div>
        <Footer/>
        <Dialog open={status !== undefined}>
            <img src={status === 'success' ? success : fail} alt='success or fail'/>
            <div className='errorDialog'>
                <div className='title'>{status === 'success' ? 'Sign up completed' : 'Sign up failed'}</div>
                <div className='errorInfo'>{status === 'success' ? 'A confirmation email will be sent to you' : 'Try refreshing the page and filling out all fields'}</div>
                <Link href={ROUTE_HOME}><div className='return'>Return to Scone</div></Link>
            </div>
        </Dialog>
    </div>
}

export default SignUpPage;