import IdealLogo from '../../Images/PaymentOptions/idealLogo.svg';
import MasterCardLogo from '../../Images/PaymentOptions/masterCardLogo.svg';
import VisaLogo from '../../Images/PaymentOptions/visaLogo.svg';
import SepaLogo from '../../Images/PaymentOptions/sepaLogo.svg'
import BancontactLogo from '../../Images/PaymentOptions/bancontactLogo.svg'
import './PaymentLogos.scss'

function PaymentLogos() {
    return <div className='paymentIcons'>
        <img className='ideal' src={IdealLogo} alt='ideal'/>
        <img className='mc' src={MasterCardLogo} alt='master card'/>
        <img className='visa' src={VisaLogo} alt='visa'/>
        <img className='sepa' src={SepaLogo} alt='visa'/>
        <img className='bancontact' src={BancontactLogo} alt='bancontact'/>
    </div>
}

export default PaymentLogos