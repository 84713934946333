import './NavBar.scss'
import MediaHuisLogo from '../../Images/Mediahuis.svg'
import scone from '../../Images/scone.svg'
import { ROUTE_HOME, ROUTE_COMMUNITY_REGISTER } from '../../constants/routes';
import {useContentfulEntity} from "../../Hooks/contentful";
import {CONTENTFUL_ENTITY_BASIC_STRINGS} from "../../constants/contentful";
import { useMediaQuery } from 'react-responsive';
import { Link } from '@material-ui/core';

function NavBar(props: NavBarProps) {
    const basicEntity = useContentfulEntity(CONTENTFUL_ENTITY_BASIC_STRINGS)
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    return <div className='header'>
        <div className='headerTitle'>
            <Link href={ROUTE_HOME}><span className='scone'><img src={scone} alt='Scone' /></span></Link>
            {!isMobile && props.showPartner &&
                <a href='https://www.mediahuis.nl/' className='partner'>
                    <div>{basicEntity?.fields.inPartnershipWith}</div>
                    <img src={MediaHuisLogo} alt='Mediahuis'/>
                </a>
            }
        </div>
        <div className='headerMenu'>
            <Link href={ROUTE_HOME}><div>{basicEntity?.fields.home}</div></Link>
            <a href='https://www.scone.ai/our-solution'>{basicEntity?.fields.about}</a>
            <a href='https://www.scone.ai/news'>{basicEntity?.fields.news}</a>
            <Link href={ROUTE_COMMUNITY_REGISTER}><div className='signupButton'>{basicEntity?.fields.communityRegister}</div></Link>
        </div>
    </div>
}

export default NavBar

interface NavBarProps {
    showPartner?: boolean;
}
