import { ApiRequestData, ApiRequestStatus } from '../../Interfaces/ApiRequestData';
import { CircularProgress, Dialog } from '@material-ui/core';
import fail from '../../Images/fail.png';

function ApiRequestWrapper(props: ApiRequestWrapperProps) {
    if (props.value.status === ApiRequestStatus.SUCCESS) {
        return props.children
    } else if (props.value.status === ApiRequestStatus.ERROR) {
        return <Dialog open={true}>
            <img src={fail} alt='fail'/>
            <div className='errorDialog'>
                <div className='title'>Something went wrong</div>
                <div className='errorInfo'>{props.value.error}</div>
            </div>
        </Dialog>
    } else {
        return <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></div>;
    }
}

export default ApiRequestWrapper

interface ApiRequestWrapperProps {
    children: any;
    value: ApiRequestData<any, any>
}