import { Offset } from '../../Interfaces/Offset';
import React, { useState } from 'react';
import './OffsetContainerHomePage.scss';
import { ROUTE_OFFSETS_INTRO } from '../../constants/routes';
import { Link } from '@material-ui/core';

function OffsetContainerHomePage(props: OffsetContainerHomePageProps) {
    const [selectedOffset, setSelectedOffset] = useState<Offset>(props.offsets[0])

    return <div className='offsetContainerHomePage'>
        <div className='offsets'>
            <div className='offsetTitle'>
                Steun deze 4 Klimaatprojecten
            </div>
            <div className='mobileSwitch'>
                <div>
                    <div className='offsetSubtitle'>
                        Compenseer jouw uitstoot die je nog niet kan voorkomen.
                    </div>
                    <div className='offsetSubtitle'>
                        Door te compenseren financier deze vier duurzame klimaatprojecten die elders voor eenzelfde hoeveelheid CO²-reductie zorgen.
                    </div>
                </div>
                <div className='offsetList'>
                    <ul>
                        {props.offsets.map(offset => {
                            return <li
                                key={offset.id}
                                onClick={() => {
                                    setSelectedOffset(offset);
                                }}
                                className={selectedOffset.id === offset.id ? 'selectedOffset' : ''}
                            >
                                <span className='offsetTechnologies'>{offset.technologies}</span>
                                <span className='offsetAllocation'>{offset.allocation}%</span>
                            </li>
                        })
                        }
                    </ul>
                </div>
            </div>
            <Link href={ROUTE_OFFSETS_INTRO}><button>Compensate CO₂</button></Link>
        </div>
        <div className='imageContainer'>
            <div className='image' style={{backgroundImage: `url('${selectedOffset.imageUrl}')`}} />
        </div>
    </div>
}

export default OffsetContainerHomePage

interface OffsetContainerHomePageProps {
    offsets: Offset[];
}